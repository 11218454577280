.defaultGames
  align-items: flex-start
  position: relative
  padding: 0 24px
  & .ant-space-item:not(:first-child)
    width: 130px
    &:last-child
        width: auto
    & .iconBox
      display: flex
      align-items: flex-end
      opacity: 0.7
      &:hover
        color: #002766
      &:active
        color: #1890ff
      &.disabled
        opacity: 0.5
        pointer-events: none
  &.firstChild .iconBox
    height: 49px
  & .iconBox
    height: 23px
.gameTemplateCurrencySelect
  font: 14px/22px "Red Hat Display Regular"
  color: $project-global-color
  width: 112px !important
  & .ant-select-arrow
    transform: rotate(270deg)
  &.ant-select-open
    & .ant-select-arrow
      transform: rotate(0deg)
  & .ant-select-selector
    & .ant-select-selection-item
      display: flex
      align-items: center
      justify-content: center
  & .icon
    width: 18px
    height: 18px
    margin-right: 8px

